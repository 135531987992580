@import '../../style/table';

.projectOverview {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    &Table {
        @extend .table;
        padding: 2rem 2.25rem;
        background-color: white;
    }
}
