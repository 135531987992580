@import '../../style/table';

.cellWebExport {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    &Table {
        @extend .table;
        padding: 2rem 2.25rem;
        background-color: white;

        th,
        td {
            max-width: 20rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        td {
            padding: 1rem;
        }

        b {
            font-size: 1rem;
        }
    }
}
