.spinner {
    svg {
        font-size: 3rem;
        color: var(--brand);
        animation: spin 1s linear infinite;
    }
}

.fullscreen {
    position: fixed;
    display: grid;
    place-items: center;
    width: 100vw;
    height: 100vh;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
