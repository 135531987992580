.eyeseeToast {
    --toastify-toast-min-height: 2.5rem;
    --toastify-color-dark: #333;
    --toastify-text-color-dark: whitesmoke;
    --toastify-font-family: Roboto, sans-serif;
    --eyesee-toast-linear-gradient: linear-gradient(90deg, #69c 0, #969 45%, #f33 80%);
    font-weight: 500;
    --toastify-color-progress-light: var(--eyesee-toast-linear-gradient);
    --toastify-color-progress-success: var(--eyesee-toast-linear-gradient);
    --toastify-color-progress-error: var(--eyesee-toast-linear-gradient);
    --toastify-color-progress-info: var(--eyesee-toast-linear-gradient);
    --toastify-color-progress-warning: var(--eyesee-toast-linear-gradient);
    text-align: center;
    border-radius: 5rem !important;

    &Container {
        width: 20rem !important;
    }

    &Body {
        font-size: 0.875rem;
    }
}
