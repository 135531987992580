.search {
    position: sticky;
    top: 0.25rem;
    box-sizing: border-box;
    margin: 0.5rem;
    border: 1px solid var(--border_gray);
    border-radius: 0.25rem;
    padding: 0.25rem;
    font-size: 0.875rem;
    z-index: 1;
}

.inputWrapper {
    position: relative;
    border-radius: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none !important;
}

.multiSelect-disabled {
    .input,
    input {
        background: #f3f4f6;
        pointer-events: none;
    }

    svg {
        color: #a4a5a6;
    }
}

.input {
    position: relative;
    border-radius: 1.5rem;
    background: white;
    border: 2px solid var(--border_gray_light);

    &-caret-hidden {
        input {
            caret-color: transparent;
        }
    }

    &Dropdown {
        border-radius: 1.5rem 1.5rem 0 0rem;
    }

    svg {
        cursor: pointer;
        background: inherit;
        font-size: 1.5rem;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    input {
        cursor: pointer;
        width: calc(100% - 1.5rem);
    }
}

.option {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    cursor: pointer;

    input {
        position: absolute;
        opacity: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding: 1.125rem;
        border-width: 0;
        cursor: pointer;
    }

    svg {
        color: var(--brand);
        flex-shrink: 0;
    }

    &:hover {
        background: rgba(206, 40, 80, 0.1);
    }
}

.optionsContainer {
    position: relative;
    top: 0;
    height: 0;
    width: 100%;

    :global .spinner {
        display: flex;
        padding: 1rem;
        font-size: 0.5rem;
        justify-content: center;
    }

    > div {
        position: absolute;
        top: 0;
        width: 100%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        max-height: 20rem;
        z-index: 2;
        background: white;
        border: solid var(--border_gray_light);
        border-width: 0 2px 2px 2px;
        border-radius: 0 0 1.5rem 1.5rem;
    }
}

.tags {
    font-size: 0.875rem;
    margin-top: 0.5rem;
    font-weight: bold;
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;

    > span {
        display: inline-flex;
        gap: 0.5rem;
        align-items: center;
        color: white;
        background: #1f2937;
        padding: 0.5rem 1rem;
        border-radius: 1.25rem;

        span {
            cursor: pointer;
        }
    }
}

.disabledTags {
    filter: opacity(0.5);
    pointer-events: none;
    cursor: auto;
}
