.radio {
    [type='radio'][value='true'],
    [type='radio']:not([value='true']) {
        position: absolute;
        left: -9999px;
    }
    [type='radio'][value='true'] + span,
    [type='radio']:not([value='true']) + span {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: var(--main_text_color);
    }
    [type='radio'][value='true'] + span:before,
    [type='radio']:not([value='true']) + span:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 18px;
        height: 18px;
        border: 1px solid var(--border_gray_light);
        border-radius: 100%;
        background: #fff;
    }
    [type='radio'][value='true'] + span:before {
        border: 1px solid var(--brand);
    }
    [type='radio'][value='true'] + span:after,
    [type='radio']:not([value='true']) + span:after {
        content: '';
        width: 12px;
        height: 12px;
        background: var(--brand);
        position: absolute;
        top: 4px;
        left: 4px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
    [type='radio']:not([value='true']) + span:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    [type='radio'][value='true'] + span:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
