.errorPage {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 12.5% 2rem;
    background: white;

    a {
        cursor: pointer;
    }

    h2 {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-size: 2rem;
        color: var(--brand);

        svg {
            font-size: 2rem;
        }
    }

    p {
        min-width: 25vw;
        width: fit-content;
        font-family: 'Lucida Console', ui-monospace;
        padding: 1rem;
        background: rgba(0, 0, 0, 0.02);
        border: 1px solid var(--border_gray);
        border-radius: 1rem;
        border-bottom-width: 2px;
    }

    span {
        align-self: center;

        a {
            color: blue;
            font-weight: 600;
        }
    }
}
