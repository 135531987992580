.table {
    width: 100%;
    overflow-x: scroll;

    th {
        > div {
            padding: 1rem;
        }
    }

    td {
        padding: 0.5rem 1rem;
    }

    tr {
        border: solid var(--border_gray_light);
        border-width: 0 0 1px 0;
    }

    ::-webkit-scrollbar-button {
        display: none;
    }

    &::-webkit-scrollbar {
        width: 0.75rem;
        height: 0.75rem;
    }

    &::-webkit-scrollbar-thumb {
        padding: 0.5em;
        background: var(--border_gray_light);
        border-radius: 0.5em;
        transition: all 200ms;

        &:hover {
            background: var(--border_gray);
        }
    }

    &::-webkit-scrollbar-track {
        position: fixed;
        bottom: 20px;
    }

    :global .spinner {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 25vh;
    }

    table {
        width: 100%;
        border: solid var(--border_gray_light);
        border-width: 0;
        border-collapse: collapse;
        text-align: left;

        thead {
            z-index: 2;
            border: 1px solid #ffffff;
            border-width: 1px 0;
            background: var(--table_header_light_gray);
            box-shadow: rgb(0 0 0 / 12%) 0px -1px 10px 0px;
            clip-path: inset(0px 0px -20px -20px);
        }

        tbody {
            font-size: 0.875rem;
            line-height: 1.25rem;

            tr {
                &:hover {
                    background-color: var(--brand_light);
                }
                td {
                    text-align: center;
                }
            }

            a {
                font-weight: 600;
                color: var(--main_text_color);
                cursor: pointer;
            }
        }
    }
}
