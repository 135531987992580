:root {
    --brand: #ce2850;
    --brand_light: #fae9ed;
    --eyesee-blue: #5986b3;
    --main_text_color: #333333;
    --secondary_text_color: #c4c4c4;
    --border_gray_light: #e5e5e5;
    --border_gray: #c4c4c4;
    --table_header_light_gray: #fcfcfc;
    --main_layout: #fafafa;
    --success_green: #92d050;
    --error_red: #ff3333;
    --surface_1: #e5e5e5;
    --button_purple: #7b61ff;
    --text-shadow-lg: 0 15px 30px rgb(0 0 0 / 50%), 0 5px 15px rgb(0 0 0 / 8%);
    --text-shadow-md: 0 15px 30px rgb(0 0 0 / 50%), 0 5px 15px rgb(0 0 0 / 4%);
    --skeleton_gray: hsl(220deg 13% 95%);
    --header-height: 3.5rem;
    --tw-gradient-from: #69c;
    --tw-gradient-to: #ff3333;
    --tw-gradient-stops: var(--tw-gradient-from), #996699,
        var(--tw-gradient-to, rgba(153, 102, 153, 0));
}
