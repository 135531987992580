.inputFieldEditorWrapper {
    width: 100%;

    .toolbarClassName {
        position: absolute;
        top: -30px;
        border: none;
        background: transparent;
        border: none;
        width: 100%;
    }
    .wrapperClassName {
        position: relative;
        width: 100%;
    }
    .editorClassName {
        min-height: 5.5rem;
        padding-left: 10px;
        padding-right: 5px;
        word-break: break-all;
        overflow: unset;
    }
}

.inputField {
    width: 100%;
    display: flex;
    background: white;
    border-radius: 1.5rem;

    &Disabled {
        > div:first-of-type {
            background: whitesmoke;
        }
        input {
            cursor: default;
        }
    }

    :global .inputFieldLabel {
        width: 9rem;
        display: flex;
        gap: 0.25rem;
        margin-bottom: 0.25rem;
        font-weight: 500;

        > span::first-letter {
            text-transform: uppercase;
        }

        &Small {
            width: 6rem;
        }
    }

    :global h1 {
        font-size: 1.5rem;
        margin: 0.75rem 0;
    }

    :focus {
        border-color: var(--brand);
    }

    > div:first-of-type {
        border: 2px solid var(--border_gray_light);
        border-radius: 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        input,
        textarea {
            border-radius: 1.5rem;
            font-size: 0.875rem;
            color: #333333;
            border: none;
            background: none;
            padding: 0.33rem 1rem;
            padding: 0px;
            width: 100%;
            &::placeholder {
                color: var(--border_gray_light);
            }
        }

        span > svg {
            margin-right: 1rem;
        }
    }
}

:global div.public-DraftStyleDefault-block,
:global div.public-DraftStyleDefault-ltr {
    margin: 0.75rem 0rem;
}

div.noBorder {
    border: none;
}
