.backdrop {
    background: #333333;
    width: 100vw;
    height: 100vh;
    opacity: 0.1;
    position: fixed;
    top: 0;
    left: 0;
}

.modal {
    position: fixed;
    z-index: 1;
    border-radius: 1rem;
    box-shadow: var(--text-shadow-md);
    padding: 1.5rem;
    top: 50%;
    transform: translateY(-50%);

    header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        h2 {
            font-size: 1.25rem;
            font-weight: 500;
            flex-grow: 1;
            text-align: center;
            margin-left: 1rem;
        }

        button {
            position: absolute;
            top: 1rem;
            right: 0.5rem;
            background: none;
            border: none;
            cursor: pointer;
            font-size: 1.5rem;
        }
    }
}
