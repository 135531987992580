.inputField {
    width: 100%;
    display: flex;
    align-items: baseline;
    background: white;
    border-radius: 1.5rem;

    :global textarea ~ .input-error-message {
        top: 6.5rem;
        left: 0.75rem;
    }

    &Disabled {
        > div:first-of-type {
            background: whitesmoke;
        }
        input {
            cursor: default;
        }
    }

    :global .inputFieldLabel {
        width: 9rem;
        display: flex;
        gap: 0.25rem;
        align-items: center;
        margin-bottom: 0.25rem;
        font-weight: 500;

        > span::first-letter {
            text-transform: uppercase;
        }

        &Small {
            width: 6rem;
        }
    }

    :focus {
        border-color: var(--brand);
    }

    .inputInnerWrapper {
        position: relative;
        border: 2px solid var(--border_gray_light);
        display: flex;
        align-items: center;

        input,
        textarea {
            font-size: 0.875rem;
            color: #333333;
            border: none;
            background: none;
            padding: 0.5rem 1rem;
            width: calc(100% - 0.5rem);
            &::placeholder {
                color: var(--border_gray_light);
            }
        }

        span > svg {
            margin-right: 1rem;
        }
    }
}

.inputFieldDropdownActive {
    > div:first-of-type {
        border-radius: 1.5rem 1.5rem 0 0rem;
    }
}
