.button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    height: 2.5rem;
    padding: 0 1.5rem;
    border-radius: 20px;
    color: white;
    border: 1px solid var(--brand);
    background-color: var(--brand);
    font-family: 'Roboto';
    font-size: 0.875rem;
    cursor: pointer;
}
