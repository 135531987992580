.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5rem;
    margin: 0 auto;
    margin-top: 1rem;
    font-size: 0.875rem;

    label {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-left: 1rem;

        select {
            border-radius: 35px;
        }
    }

    > div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 1rem;
    }

    &Numbers {
        display: flex;
        gap: 0.5rem;
        margin: 0 1rem;

        :global span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2rem;
            height: 2rem;
            border-radius: 100%;
            color: var(--border_gray);
            cursor: pointer;

            &.active {
                color: var(--main_text_color);
                background-color: var(--brand_light);
            }
        }
    }
}
