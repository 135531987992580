.platform {
    iframe {
        width: 400px;
        height: 650px;
        border: 1px solid #c4c4c4;
        border-radius: 1rem;
        pointer-events: none;
    }

    article:nth-child(2) {
        display: flex;
        gap: 2rem;
        padding: 2rem;
    }
}
