.checkbox {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    svg {
        color: var(--brand);
    }
    cursor: pointer;
}

.disabled {
    cursor: auto;
    svg {
        filter: saturate(0);
    }
}
