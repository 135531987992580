@import '../../style/table';

.projectPage {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    h2 {
        margin: 0;
        font-size: 1.25rem;
    }

    &Table {
        @extend .table;
        padding: 2rem 2.25rem;
        background-color: white;

        > div {
            display: flex;
            align-items: center;
        }

        > div:last-child {
            justify-content: center;
            padding-top: 2rem;
        }
    }

    &Name {
        display: flex;
        gap: 1.5rem;
        padding-bottom: 2rem;

        div:last-child {
            display: flex;
            align-items: center;
            gap: 1.2rem;

            > div {
                position: relative;

                span {
                    position: absolute;
                    top: 2.4rem;
                    left: 0;
                    font-size: 0.75rem;
                    color: var(--error_red);
                }
            }
        }

        input {
            border: 1px solid var(--border_gray_light);
        }

        svg {
            cursor: pointer;
        }
    }
}

:global #modal {
    .actionButtons {
        display: flex;
        justify-content: center;
        gap: 1.5rem;
        margin-top: 2rem;

        button {
            text-transform: capitalize;
            :first-child {
                background-color: white;
            }
        }
    }
}
