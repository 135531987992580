.previewLink {
    position: fixed;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 32rem;
    padding: 1rem;
    background-color: #fbfbfb;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

    input {
        width: 80%;
        &:focus {
            border: 1px solid var(--brand);
        }
    }

    i,
    a {
        color: var(--brand);
        opacity: 0.7;
        cursor: pointer;
        font-size: 1.25rem;
        &:hover {
            opacity: 1;
        }
    }
}
