@import '../../style/table';

.cellStats {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    &Table {
        @extend .table;
        padding: 2rem 2.25rem;
        background-color: white;

        td {
            padding: 1rem;
        }

        b {
            font-size: 1rem;
        }
    }
}
