.iconButton {
    display: inline-flex;
    padding: 0.5rem;
    border: none;
    background-color: transparent;
    color: var(--main_text_color);
    font-size: large;

    &:hover {
        background-color: rgba(#ce2850, 0.2);
        border-radius: 0.5rem;
        color: rgba(0, 0, 0, 0.9);
        cursor: pointer;
    }

    &:disabled {
        opacity: 0.3;
        pointer-events: none;
    }
}
