.task {
    position: relative;

    summary {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        background-color: #fbfbfb;

        h3 {
            margin: 0;
            font-size: 1rem;
        }

        > div {
            display: flex;
            gap: 0.75rem;
        }
    }

    fieldset {
        display: flex;
        gap: 4rem;
        padding: 1rem 2rem;

        > article {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;

            > div {
                display: flex;
                align-items: center;
                gap: 1rem;
            }
        }
    }

    .label {
        font-weight: 500;
        width: 9rem;
    }
}

.editorWrapper {
    position: relative;
    gap: 1rem;
}
