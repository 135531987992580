.tableHead {
    th {
        div {
            display: flex;
            justify-content: center;
            gap: 0.5rem;
        }
    }

    .sortIcons {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;

        .activeSort {
            path {
                fill: var(--main_text_color);
            }
        }
    }
}
