.navTabs {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        display: flex;
        align-items: center;
        gap: 1rem;

        &:first-child {
            font-weight: 500;
            font-size: 1.125rem;
        }
    }
}
