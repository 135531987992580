.header {
    position: sticky;
    z-index: 10;
    top: 0;
    height: var(--header-height);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    background: white;
    // background-image: linear-gradient(to right, var(--tw-gradient-stops));
    box-shadow: 0px -1px 10px 0px rgba(0, 0, 0, 0.25);
    padding: 0.5rem 4rem 0.5rem 2rem;
    white-space: nowrap;
    color: var(--main_text_color);

    :any-link {
        color: var(--main_text_color);
        text-decoration: none;
    }

    :global .button[type='button'] {
        color: var(--brand);
    }

    > div {
        display: flex;
        gap: 3rem;
    }

    > button:first-of-type {
        margin-left: auto;
        gap: 0.125rem;

        svg {
            transform: translateY(-0.05rem);
        }
    }

    svg {
        flex-shrink: 0;
        width: 7rem;
        height: 100%;
        padding: 0.25rem;
        transform: translateY(2px);
        cursor: pointer;
    }

    nav {
        display: flex;
        gap: 2.5rem;
        align-items: center;
        font-size: 1.125rem;
    }
}

:global {
    nav a.active {
        font-weight: 600;
        color: var(--main_text_color);
    }
}

.userDropdown {
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;

    span {
        &:hover {
            color: var(--brand);
            transition: all 150ms ease-in-out;
        }

        white-space: nowrap;
    }

    img {
        width: 2rem;
        height: 2rem;
        border-radius: 100%;
    }

    svg {
        width: 2.5rem;
    }

    menu {
        position: absolute;
        top: 100%;
        width: 100%;
        padding: 0.5rem;
        border: var(--border_gray_light) 2px solid;
        background: white;
        box-shadow: 0px -1px 10px 0px rgba(0, 0, 0, 0.25);
        clip-path: inset(0px 0px -20px -20px);

        button {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            background-color: transparent;

            svg {
                width: 2rem;
                margin-bottom: 0.25rem;
            }

            &:hover {
                color: var(--brand);
                transition: all 150ms ease-in-out;
            }
        }
    }
}
