.cellPage {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    .tasksContainer {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .createTaskBottom {
        display: flex;
        justify-content: center;
        padding: 1rem;
    }

    &Main {
        padding: 0 2rem;
        background-color: white;
    }

    &ExpandableWrapper {
        details {
            summary {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid var(--border_gray_light);

                h2 {
                    font-weight: 500;
                    font-size: 1.125rem;
                }
            }
        }

        &Body {
            padding: 1rem 0;

            > div {
                display: flex;
                justify-content: flex-end;
                padding: 0.5rem 0;

                label {
                    margin-right: 1rem;
                    font-weight: 500;
                }
            }
        }
    }

    &TaskMenu {
        display: flex;
        justify-content: flex-end;
        padding: 1rem;
    }

    &CreateTask {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        font-weight: 500;
        color: var(--brand);
        cursor: pointer;
    }

    &Header {
        display: flex;
        gap: 5rem;
        justify-content: space-between;
        align-items: center;
        margin-top: 1rem;
        border-bottom: 1px solid var(--border_gray_light);

        h2 {
            font-weight: 500;
            font-size: 1.125rem;
        }

        &Nav {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            > div {
                display: flex;
                align-items: center;
                gap: 0.5rem;

                span {
                    user-select: none;
                }
            }

            &Active {
                cursor: pointer;
            }

            &Disabled {
                color: var(--secondary_text_color);
            }
        }
    }

    &Body {
        display: flex;
        gap: 5rem;
        justify-content: flex-start;
        padding: 1rem 0;

        > div {
            display: flex;
            flex-direction: column;
            gap: 1.25rem;
        }
    }
}
