.main {
    height: 100vh;
    background-color: whitesmoke;
    color: var(--main_text_color);
}

.loadingScreen {
    width: 100%;
    height: calc(100vh - var(--header-height));
    display: grid;
    place-items: center;
}

.layout {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 1.5rem;
    background-color: whitesmoke;
}

:global .menuBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.25rem;
    padding: 0.75rem 2rem;
    background-color: white;

    a {
        color: var(--main_text_color);
    }

    h1 {
        margin: 0;
        padding: 0;
        font-size: 1.125rem;
        font-weight: 500;
    }

    > div {
        display: flex;
        gap: 2rem;
    }

    &Actions {
        display: flex;
        gap: 1.5rem;
        align-items: center;
        padding: 0.5rem;

        a {
            display: flex;
            align-items: center;
            gap: 0.75rem;
            font-weight: 500;

            &:nth-child(2) {
                color: var(--brand);
            }
        }
    }

    &Buttons {
        padding: 0.5rem;
        button {
            display: flex;
            align-items: center;
            gap: 0.75rem;
            font-size: 1rem;
            font-weight: 500;
            border: none;
            background: none;
            cursor: pointer;

            &:nth-child(2) {
                color: var(--brand);
            }

            &.redBtn {
                color: var(--brand);
            }
        }
    }
}

:global .linkCell {
    display: inline-flex;
}

:global .inputWrapper {
    width: 13rem;

    &Large {
        width: 24rem;
        border-radius: 1.5rem;
    }
}

:global .radioWrapper {
    position: relative;
    display: flex;
    gap: 1rem;
    > span {
        font-weight: 500;
    }
}

:global .textArea {
    &Wrapper {
        border-radius: 0.5rem;
    }
}

:global .expandableSection {
    details {
        margin: 1rem 0;
        summary {
            background-color: #fbfbfb;
            padding: 0 1.5rem;

            h3 {
                font-size: 1rem;
                text-transform: uppercase;
            }
        }
        > div {
            display: flex;
            justify-content: space-between;
            padding: 1.5rem 3.5rem 0.5rem;

            > div {
                display: flex;
                flex-direction: column;
                gap: 1.125rem;
            }
        }
    }
}

:global .editorWrapper {
    margin-top: 1rem;
}

:global .page {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    > article {
        background-color: white;
    }
}

:global .noBorder {
    border: none;
}

:global .downloadLinks {
    position: absolute;
    z-index: 1;
    top: -1rem;
    left: -8rem;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 0.25rem;
    background: #fff;
    white-space: nowrap;
    box-shadow: 0 0 5px #cfcfcf;

    a,
    button {
        padding: 0.25rem;
        font-weight: 600;
        color: var(--main_text_color);
        font-family: 'Roboto';
    }

    button {
        background: none;
        font-size: 0.875rem;
        text-align: left;
    }
}

:global .relative {
    position: relative;
}
