.switch {
    display: flex;
    align-items: center;

    > span {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 23px;
    }

    input {
        position: relative;
        opacity: 0;
        height: 30px;
        width: 60px;
        z-index: 1;
        cursor: pointer;
    }
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: '';
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: var(--brand);
}

input:focus + .slider {
    box-shadow: 0 0 1px var(--brand);
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

:global .switch .input-error-message {
    position: absolute;
    top: 1.5rem;
}
