@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background-color: whitesmoke;
}

* {
    box-sizing: border-box;
}

*:focus {
    outline: none;
    box-shadow: none !important;
}

fieldset {
    border: none;
}

input {
    font-size: 0.875rem;
    color: #333333;
    border: none;
    background: none;
    padding: 0.5rem 1rem;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
}

textarea {
    font-family: 'Roboto', sans-serif;
}

details {
    summary {
        list-style: none;
        cursor: pointer;

        &::-webkit-details-marker,
        &::marker {
            display: none;
            list-style: none;
        }
    }
}

select {
    appearance: none;
    padding-top: 0.25rem;
    padding-right: 3rem;
    padding-bottom: 0.25rem;
    padding-left: 0.75rem;
    line-height: 1.5rem;
    background-image: url('./assets/svg/select_arrow.svg');
    background-position: right 0.8rem center;
    background-repeat: no-repeat;
    background-size: 0.75rem;
}

menu {
    margin: 0;
}

a {
    text-decoration: none;
}

button {
    border: none;
    cursor: pointer;
    &:disabled {
        cursor: not-allowed;
    }
}

textarea {
    resize: none;
}
